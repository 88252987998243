import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedBlogSection from "../components/pages/Blogs/FeaturedBlogSection"
import BlogsWithSearchSection from "../components/pages/Blogs/BlogsWithSearchSection"
import RecommendedBlogs from "../components/common/RecommendedBlogs"
import BusinessModelsWithFormSection from "../components/pages/Blogs/BusinessModelsWithFormSection"

const Blogs = () => (
  <Layout>
    <Seo
      title="Blogs"
      description={`Explore our blog for insights, tips, and updates on expense tracking, tax management, and financial tools. Stay informed and optimize your financial strategy with Synkli.`}
    />

    <FeaturedBlogSection />

    <BlogsWithSearchSection />

    <RecommendedBlogs />

    <div className="pb-10">
      <BusinessModelsWithFormSection
        title={"Synkli – Your Ultimate Business Management Partner"}
        description={`Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`}
      />
    </div>
  </Layout>
)

export default Blogs
