import { useCallback } from 'react';

const useReadingTime = () => {
  const calculateReadingTime = useCallback((blogContent) => {
    const wordsPerMinute = 40; // Average reading speed

    // Ensure blogContent is an array
    if (!Array.isArray(blogContent)) {
      return 0; // Default reading time if blogContent is not an array
    }

    const text = blogContent.map(section => {
      const sectionText = section.description || '';
      const listText = section.list ? section.list.join(' ') : '';
      return `${sectionText} ${listText}`;
    }).join(' ');

    const wordCount = text.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);

    return readingTime;
  }, []);

  return { calculateReadingTime };
};

export default useReadingTime;
