import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import InfoTag from "../atoms/InfoTag"
import { Link } from "gatsby"
import { author, blogsPostTypeSlug } from "../../Content/Global"
import useContentMaxlenghtWithDots from "../../hooks/useContentMaxlenghtWithDots"
import ImageUrlRenderer from "../atoms/ImageUrlRenderer"

const BlogCardForBlogsPage = ({
  componentEC,
  date,
  category,
  title,
  slug,
  description,
  cardImage,
  time,
}) => {
  const ImageURL = ImageUrlRenderer({ img: cardImage })
  return (
    <div
      className={
        "bg-white shadow-md shadow-[#001F1F24]-500 border border-[#001F1F08] rounded-[20px] px-4 py-3 " +
        componentEC
      }
    >
      <Link to={blogsPostTypeSlug + slug + "/"}>
        <div
          className="min-h-[250px] md:min-h-[200px] lg:min-h-[250px] w-[100%] bg-cover bg-center rounded-[16px]"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url('${ImageURL}')`,
          }}
        ></div>
      </Link>

      <div className="flex flex-col gap-5 pb-4 mt-5">
        <div className="flex flex-wrap gap-3">
          <InfoTag text={date} />
          <InfoTag
            text={category}
            componentEC={`bg-[#F881F330] text-[#F881F3]`}
          />
        </div>

        <Link to={blogsPostTypeSlug + slug + "/"}>
          <h2
            className={`text-[20px] md:text-[22px] leading-[1.2em] font-[600] text-[#000000] md:min-h-[58px]`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </Link>

        <p className="text-[14px] md:text-[16px] leading-[1.4em] ">
          {useContentMaxlenghtWithDots(description, 106)}
        </p>

        <div className="flex justify-between items-center">
          <div className="flex flex-wrap items-center gap-3">
            <div className="max-w-[38px]">
              <ImageRenderer img={author.image.path} alt={author.image.alt} />
            </div>
            <h5 className="text-[14px] md:text-[16px] lg:text-[16px] leading-[1.4em]">
              {author.name}
            </h5>
          </div>
          <h5 className="text-[14px] md:text-[16px] lg:text-[16px] leading-[1.4em]">
            {time} min read
          </h5>
        </div>
      </div>
    </div>
  )
}

export default BlogCardForBlogsPage
