import React from "react"
import ImageRenderer from "../../atoms/ImageRenderer"
import { author } from "../../../Content/Global"

const FeaturedBlogSection = () => {
  return (
    <div className="synkli-section--horizontal-space">
      <div className="mt-20 md:mt-24 mb-10 bg-[#0A1E46] rounded-[25px]">
        <div className="flex flex-col md:flex-row gap-5 items-center">
          <div className="flex-[2] flex flex-col justify-between gap-4 md:gap-10 px-6 md:pl-10 lg:pl-16 py-10 md:py-16">
            <h2 className="text-white text-[24px] md:text-[30px] lg:text-[48px] leading-[1.2em] font-[700]">
              Maximize Tax <span className="text-[#B695F8]">Deduction</span> A
              Comprehensive Guide
            </h2>
            <p className="text-white text-[18px] lg:text-[20px] leading-[1.4em] font-[300]">
              Break down common tax deductions for individuals and businesses,
              including mileage, home office, and business expenses.
            </p>
            <div className="flex flex-wrap items-center gap-4">
              <div className="max-w-[48px]">
                <ImageRenderer img={author.image.path} alt={author.image.alt} />
              </div>
              <h5 className="text-white text-[16px] lg:text-[20px] leading-[1.4em] font-[300]">
                {author.name}
              </h5>
              <h5 className="text-white text-[16px] lg:text-[20px] leading-[1.4em] font-[300]">
                <span className="inline-block w-[15px] h-[15px] rounded-full bg-[#B695F8]"></span>{" "}
                12 min read
              </h5>
              <h5 className="text-white text-[16px] lg:text-[20px] leading-[1.4em] font-[300]">
                <span className="inline-block w-[15px] h-[15px] rounded-full bg-[#B695F8]"></span>{" "}
                12 May, 2024
              </h5>
            </div>
          </div>
          <div className="flex-[1]">
            <ImageRenderer
              img="featured-blog-image"
              alt="featured-blog-image"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedBlogSection
